import React from "react";
import "../style/aboutUs.css";
import Paragraph from "antd/es/skeleton/Paragraph";
export default function AboutUs() {
  const visionText = "Support every aspiration with affordable education supports.";
  const journeyText = [
    "LeadLink Community Culture Service, headquartered in Vancouver, is committed to providing philanthropic and affordable cultural education services.",
    "Founded by several alumni of Duke University's Sanford School of Public Policy, LeadLink has been passionate about advocating and developing education initiatives since our time at Duke University. During our time at Duke, under the guidance of the dean, we completed research on the development of primary and higher education in China. We have been trying to address the uneven distribution of educational resources and the scarcity of high-quality education in China, which has left many Chinese children unable to enjoy extracurricular education that suits their interests and explore their academic passions.",
    "LeadLink believes every far-reaching dream deserves to be seen, and every ambition needs support.",
  ];

  const teamMembers = [
    {
      title: "Director",
      name: "Li (Livi) Jiang",
      qualifications: [
        "China Higher Education Teaching Qualification Holder",
        "Serve in Higher Education Institutes in the USA and China for more than 10 years",
        "Master of International Development Policy, Duke University",
      ],
    },
    {
      title: "Academic Director",
      name: "Bumtsend Gombo",
      qualifications: [
        "Deputy Director of Ministry of Foreign Affairs, Mongolia",
        "Serve in Mongolia Consulate in China and the USA",
        "Master of International Development Policy, Duke University",
      ],
    },
    {
      title: "Legal Affairs Director",
      name: "Iris Li",
      qualifications: [
        "Passed China Bar Exam and New York Bar Exam",
        "Serve in Red Circle and Vault Law Top 10 Wall Street law firms",
        "Master of Law, the University of Southern California",
      ],
    },
    {
      title: "Financial Director",
      name: "Simon Sun",
      qualifications: [
        "CFA and CPA Holder",
        "Served in HSBC and Haitong Securities Investment Banking Department",
        "Master of Science in Economics, Purdue University",
      ],
    },
    {
      title: "Strategy Director",
      name: "Xinyi Guo",
      qualifications: [
        "CFA ESG Investing Holder",
        "Serve in the Sustainable Development Consulting Department at Ernst & Young",
        "Master of Environmental Management, Duke University",
      ],
    },
  ];

  return (
    <div className="about-us-page">
      <div className="company-introduction">
        <h2>Our Vision</h2>
        <p>{visionText}</p>
      </div>

      <div className="company-history">
        <h2>Our Journey</h2>
        {journeyText.map((paragraph, index) => (
          <p key={index}>{paragraph}</p>
        ))}
      </div>
      <div className="team-info">
      <h2>Our Team</h2>
        {teamMembers.map((member, index) => (
        <div key={index} className="team-member">
          <h3> {member.title} - {member.name}</h3>
          <ul>
            {member.qualifications.map((qualification, qIndex)=> (
              <li key={qIndex}>{qualification}</li>
            ))}
          </ul>
          </div>
      ))}
      </div>
    </div>
  );
}
