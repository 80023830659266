import React from "react";
import "../style/footer.css"; // 创建 footer.css 样式文件以自定义样式

export default function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-logo">
          <h1>LEADLINK</h1>
          <p>Education for Every Aspiration</p>
        </div>
        <div className="footer-contact">
          <h2>Contact Us</h2>
          <p>Email: media@leadlink.ca</p>
          <p>Address: 8155 Capstan way, Vancouver, BC, V6X 0V3</p>
        </div>
        <div className="footer-social">
          <h2>Follow Us</h2>
          <a href="https://www.facebook.com/leadlinkedu" target="_blank" rel="noopener noreferrer">
            Facebook
          </a>
          <a href="https://twitter.com/leadlinkedu" target="_blank" rel="noopener noreferrer">
            Twitter
          </a>
          <a href="https://www.linkedin.com/company/leadlinkedu" target="_blank" rel="noopener noreferrer">
            LinkedIn
          </a>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; {new Date().getFullYear()} LeadLink Education | All rights reserved | BN/Registration Number: 708660816BC0001</p>
      </div>
    </footer>
  );
}
