import { Outlet, Link } from "react-router-dom";
import "../style/appLayout.css";
import Footer from "./Footer";
export default function AppLayout() {
  return (
    <div className="app">
      <div className="app-header">
        <nav className="menu">
          <ul className="menu-list">
            <li>
              <Link to="/home">Home</Link>
            </li>
            <li>
              <Link to="/aboutUs">About Us</Link>
            </li>
            <li>
              <h1>LeadLink</h1>
            </li>
            <li>
              <Link to="/program">Community Program</Link>
            </li>
            <li>
              <Link to="/joinUs">Join Us</Link>
            </li>
          </ul>
        </nav>
      </div>
      <div className="app-content">
        <Outlet />
      </div>
      <div>
            <Footer />
      </div>
  

    </div>
  );
}
