import React from "react";
import "../style/joinUs.css";

export default function JoinUs() {
  const email = "hr@leadlink.ca";

  const jobOpenings = [
    {
      title: "Job Opening 1: Volunteer Teacher",
      description: [
        "Be responsible for delivering bilingual online lessons for K-12, including preparing educational material, fostering a positive learning environment, and helping students achieve their educational goals.",
        "Be responsible for course agenda design, including lecture slides or other supporting materials."
      ],
      qualifications: [
        "Students currently enrolled in Grade 6 – Grade 12.",
        "Fluent in English (Bilingual Language is preferred).",
        "Passionate for social connection and community service.",
        "An open mind for a multicultural environment.",
        "Access to Online teaching platforms and be familiar with using PowerPoints."
      ]
    },
    {
      title: "Job Opening 2: Part-Time Teacher",
      description: [
        "Lead classroom instruction through bilingual online lessons catered to K-12 students",
        "Develop and prepare comprehensive educational materials that stimulate intellectual curiosity",
        "Track and evaluate student progress, providing class feedback",
        "Design a structured course agenda, including creating lecture slides, multimedia presentations, and supplementary teaching aids."
      ],
      qualifications: [
        "With a Bachelor’s Degree or above, any majors are welcomed (Also welcome for in-school college students)",
        "Proficient in English; bilingual abilities strongly preferred",
        "Demonstrated passion for fostering educational growth, social engagement, and community service.",
        "Proficient in using online teaching platforms and educational technology, including PowerPoint, virtual classroom software, and other digital resources.",
      ]
    }
  ];

  return (
    <div className="joinUsPage">
      <div className="joinUsHeader">
        <h1>Join Our Team</h1>
        <p>
          We're looking for talented individuals to be part of our journey. If
          you're interested, please send your resume to 📩{" "}
          <span className="email-address">{email}</span>.
        </p>
      </div>
      <div className="joinUsContent">
        {jobOpenings.map((job, index) => (
          <div key={index} className="jobOpening">
            <h2>{job.title}</h2>
            <p>
              <strong>Job Description📋:</strong>
              <ul>
                {job.description.map((item, i) => (
                  <li key={i}>{item}</li>
                ))}
              </ul>
              <strong>Qualifications🎓:</strong>
              <ul>
                {job.qualifications.map((item, i) => (
                  <li key={i}>{item}</li>
                ))}
              </ul>
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}
