import "../style/home.css";
import homeImg1 from "../assets/homeImage/home1.jpg";
import homeImg2 from "../assets/homeImage/home2.jpg";
import homeImg3 from "../assets/homeImage/home3.jpg";
import homeImg4 from "../assets/homeImage/home4.jpg";
import homeImg5 from "../assets/homeImage/home5.jpg";
import homeImg6 from "../assets/homeImage/home6.jpg";

import React, { useState } from "react";
import { Carousel } from "antd";
import { Link } from "react-router-dom";

export default function Home() {
  const images = [homeImg1, homeImg2, homeImg3, homeImg4, homeImg5, homeImg6];
  const [certificateNumber, setCertificateNumber] = useState(""); 
  return (
    <div className="homePage">
      <div className="homeContent">
        <div className="homePage-image">
          <Carousel autoplay>
            {images.map((img, index) => (
              <div key={index}>
                <img src={img} alt={`homeImg-${index + 1}`} />
              </div>
            ))}
          </Carousel>
        </div>
        <div className="homePage-description">
          <h2>EDUCATION FOR EVERY ASPIRATION</h2>
          <p>
            LeadLink insists on delivering affordable education for every
            individual. LeadLink believes high-quality education support should
            not be a privilege but a universal right attainable for every child,
            regardless of background or life goals.
          </p>
        </div>
      </div>
      <div className="homePage-search">
      <p className="search-description">Enter your certificate number above to check your certificate details.</p>
        <input
          type="text"
          placeholder="Enter your certificate number"
          value={certificateNumber}
          onChange={(e) => setCertificateNumber(e.target.value)}
        />
        <Link to={`/certificate/${certificateNumber}`}>
          <button>Check Your Certificate</button>
        </Link>
      </div>
    </div>
  );
}
