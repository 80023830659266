import React from "react";
import "../style/program.css";
import program1 from "../assets/programImage/program1.jpg"
import program2 from "../assets/programImage/program2.jpg"
import program3 from "../assets/programImage/program3.jpg"
export default function Program() {
  const projects = [
    {
      title: "Sino-Canadian Children Reading Camp",
      description:
        "The Sino-Canadian Children's Reading Camp is a total free online reading class initiative launched by LeadLink. Canadian children act as leaders of the program and guide Chinese children in reading English picture books. The activity aims to enhance the English-speaking skills of Chinese children while also providing Canadian children an opportunity to contribute to society.",
      programImg: program1
    },
    {
      title: "LeadLink Affordable Online Class Program",
      description:
        "Online Class Program offers affordable online academic classes catered to the K-12 age groups. They aim to provide cost-effective and engaging exploratory courses in nature and humanities.",
        programImg: program2

    },
    {
      title: "International Cultural Exchange Program",
      description:
        "The International Cultural Exchange Program includes online and offline cross-border exchange activities. We have held many summer camps in China, Japan, and Canada. Our exclusively designed international summer camp offers children a unique cultural journey.",
        programImg: program3

    },
  ];

  return (
    <div className="program-page">
      {projects.map((project, index) => (
        <div key={index} className="program-container">
          <div className="program-detail">
            <h2>{project.title}</h2>
            <div className="program-content">
              <img src={project.programImg} alt={project.title} className="program-image"/>
              <p>{project.description}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
