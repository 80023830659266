import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Program from "./component/Program";
import NotFound from "./component/NotFound";
import AppLayout from "./component/AppLayout";
import AboutUs from "./component/AboutUs";
import JoinUs from "./component/JoinUs";
import Home from "./component/Home";
import CertificateDetail from "./component/CertificateDetail";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<AppLayout />}>
      <Route index element={<Navigate to="/home" />} />
        <Route path="/home" element={<Home />} />
        <Route path="/aboutUs" element={<AboutUs />} />
        <Route path="/program" element={<Program />} />
        <Route path="/joinUs" element={<JoinUs />} />
        <Route path="/certificate/:certificateNumber" element={<CertificateDetail />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  </BrowserRouter>
);
