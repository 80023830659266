import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "../style/certificateDetail.css";
export default function CertificateDetails() {
  const { certificateNumber } = useParams();
  const [certificate, setCertificate] = useState(null);
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/certificates/${certificateNumber}`
        );
        if (!response.ok) {
          throw new Error(`Request failed with status: ${response.status}`);
        }
        const data = await response.json();
        data.issueDate = formatDate(data.issueDate);
        setCertificate(data);
      } catch (error) {
        console.error("Error fetching certificates:", error);
      }
    };
    fetchData();
  }, [certificateNumber]);
  return (
    <div className="certificateDetails">
      <h2>Certificate Details</h2>
      {certificate ? (
        <div>
          <p>Obtained Date: {certificate.issueDate}</p>

          <p>First Name: {certificate.firstName}</p>
          <p>Last Name: {certificate.lastName}</p>
          <p>This is a proof for successfully completing the {certificate.type}. </p>
        </div>
      ) : (
        <div className="errorMessage">
          <p>Sorry, the requested certificate record was not found.</p>
        </div>
      )}
    </div>
  );
}
